<template>
  <div class="monitoring">
    <el-row>
      <div class="expand-tree-box">
        <!-- 树 -->
        <car-tree
          ref="carTree"
          :showCheckBox="true"
          :trailStep="trailStep"
          :expand="true"
          :bLoading="bLoading"
          :carTreeHeight="CarTreeHeight"
          :treeBoxHeight="treeBoxHeight"
          :system="system"
          @handleNodeClick="handleNodeClick"
          @handleCheck="handleCheck"
          @handleToPlayVideo="handleToPlayVideo"
          @handleHistoryVideo="handleHistoryVideo"
          @handleContextMenu="handleContextMenu"
          @closeVehDialog="closeVehDialog"
          @stopRealVideoCard="stopRealVideoCard"
          @stopHistioryVideoCard="stopHistioryVideoCard"
          @pushTableToAct="pushTableToAct"
          @carCalling="carCalling"
        ></car-tree>
      </div>
      <!-- 地图 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mymap-box">
        <veh-map
          style="overflow: hidden"
          :isClickCar="isClickCar"
          :clickCarCph="clickCarCph"
          :reqData="reqData"
          :system="system"
          :configObj="locations"
          :locatePoint="locatePoint"
          :carPositionReq="carPositionReq"
          :showRealVideoCard="showRealVideoCard"
          :showhistoryVideoCard="showhistoryVideoCard"
          ref="myMap"
          @loadingHide="loadingHide"
          @closeTab="closeTab"
          @showTrailReplay="showTrailReplay"
          @showTrailReplayNotice="showTrailReplayNotice"
          @showHistoryTable="showHistoryTable"
          @showCarDetail="showCarDetail"
          @showVideoCard="showVideoCard"
          @cancelClickCar="cancelClickCar"
        ></veh-map>
      </el-col>
    </el-row>
    <!-- 实时数据 报警数据  指令信息 -->
    <tabTable
      ref="tabTable"
      v-show="trailStep == 0"
      :carPositionReq="carPositionReq"
      :tabHeight="TabHeight"
      :showTab="showTab"
      :showTabName="showTabName"
      @narrow="narrow"
      @enlarge="enlarge"
      style="z-index: 800; background: white"
    />
    <!-- 实时轨迹点位   -->
    <plentyTable
      v-if="trailStep >= 2"
      ref="plTable"
      :system="system"
      :paramData="paramData"
      @locatePointShow="locatePointShow"
    />
    <!-- / **点击车辆显示信息*/:area="area"
    :street="street"-->
    <car-dialog
      v-if="dialogTableVisible"
      :titlex="plate"
      :address="address"
      :checkObj="carDetailObj"
      @toHandlePlayHisVideo="toHandlePlayHisVideo"
      @toHandlePlayVideo="toHandlePlayVideo"
      @toHandleTrail="toHandleTrail"
      @dialogVisible="handleDialog"
      :style="carDialogStyle"
    ></car-dialog>
    <!-- 实时视频 -->
    <realVideo
      id="realVideoBox"
      ref="video"
      @stopAllVideo="stopAllRealVideo"
      @exit="exitRealVideo"
      @closePlay="closeRealPlay"
      @closeDialog="closeRealVideo"
      :style="realVideoStyle"
      v-if="showRealVideoCard"
    />
    <!-- 历史视频 -->
    <!-- 删除的项：@closeDialog="closehistoryVideo" @stopAllVideo="stopAllHistoryVideo" -->
    <historyVideo
      id="historyVideoBox"
      ref="historyVideo"
      @handleChannelClick="handleChannelClick"
      @close="closeAllHistoryVideo"
      @downLoadHistoryVideo="downLoadHistoryVideo"
      @playHistoryVideo="playHistoryVideo"
      @initParameter="initParameter"
      @showDetail="showDetail"
      :isJessibucaPro="true"
      :style="historyVideoStyle"
      v-if="showhistoryVideoCard"
    />
    <!--历史视频 下载时间限制 -->
    <el-dialog
      title="下载历史视频"
      width="500px"
      :visible.sync="dialogDownloadVisible"
      v-if="dialogDownloadVisible"
      top="18vh"
    >
      <downloadLimit :params="dowloadParams" @submit="toHistoryDowload" />
    </el-dialog>
    <el-dialog
      :title="historyTitle"
      width="48vw"
      :visible.sync="dialogPlayVisible"
      v-if="dialogPlayVisible"
      top="14vh"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <div class="card-body" v-loading="loading">
        <videoList
          ref="flvPlayModule"
          :buttonValue="buttonValue"
          :isHistory="true"
          :showVideoControl="showVideoControl"
          :timeRound="timeRound"
          @dragChangeVideoTime="dragChangeVideoTime"
        ></videoList>
      </div>
    </el-dialog>
    <!-- 查看历史视频详情 -->
    <el-dialog
      title="详情"
      width="48vw"
      :visible.sync="dialogDetailVisible"
      v-if="dialogDetailVisible"
      top="16vh"
    >
      <div class="info">
        <div class="single">
          <div class="label">车牌号码</div>
          <div class="information">{{infoPlate}}</div>
        </div>
        <div class="flx">
          <div class="single">
            <div class="label">开始时间</div>
            <div class="information">{{detailInfo.beginTime}}</div>
          </div>
          <div class="single" style="margin-left:30px;">
            <div class="label">结束时间</div>
            <div class="information">{{detailInfo.endTime}}</div>
          </div>
        </div>
        <div class="single">
          <div class="label">报警状态</div>
          <div class="information">{{detailInfo.alarmFlag}}</div>
        </div>
        <div class="flx">
          <div class="single w25">
            <div class="label">时长</div>
            <div class="information">{{formateTimeRound(detailInfo.val)}}</div>
          </div>
          <div class="single w25">
            <div class="label">大小（MB）</div>
            <div class="information">{{detailInfo.fileSize | formataSize}}</div>
          </div>
        </div>
        <div class="flx">
          <div class="single w25">
            <div class="label">码流类型</div>
            <div class="information">{{detailInfo.streamType==1?'主码流':detailInfo.streamType==2?'子码流':'所有码流'}}</div>
          </div>
          <div class="single w25">
            <div class="label">通道号</div>
            <div class="information">CH{{detailInfo.channel}}</div>
          </div>
          <div class="single w25">
            <div class="label">音视频资源类型</div>
            <div class="information">{{detailInfo.resType==1?'音频':detailInfo.resType==2?'视频':detailInfo.resType==3?'视频或音频':'音视频'}}</div>
          </div>
          <div class="single w25">
            <div class="label">存储类型</div>
            <div class="information">{{detailInfo.storageType==1?'主存储器':detailInfo.storageType==2?'灾备存储器':'所有存储器'}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSpotCheck, queryVehicleCardById } from "@/api/lib/refreshAlarm";

import carTree from "@/components/carTree/carTree.vue";
import vehMap from "./map/map.vue";
import dialog from "./dialog/dialog.vue";
import tabTable from "./table/tabTable.vue";
import plentyTable from "./plTable/plTable.vue";
import realVideo from './realVideo/VideoJessibucaWs.vue';//jessibucaPro播放器(新播放器更换)
import historyVideo from "./historyVideo/historyVideo.vue";
import downloadLimit from "./historyVideo/downloadLimit/downloadLimit.vue";

import videoList from './realVideo/videoListjessibucaWs.vue';//jessibucaPro播放器(新播放器更换)
import getPlace from '@/common/utils/getPlace.js'
import { oneGpsToLngLat } from '@/common/utils/index'
import { YHTools } from '@/assets/js/Tools.js'

import { mapGetters } from "vuex";

export default {
  name: "monitoring",
  components: {
    "car-tree": carTree, //车辆树
    "car-dialog": dialog, //车辆详细信息面板
    vehMap, //地图
    tabTable, //底部table
    plentyTable, //轨迹table
    realVideo,
    historyVideo,
    downloadLimit,
    videoList
  },
  data() {
    return {
      clickCarCph: null, //单击节点与点名的车辆id
      isClickCar: false, //单击节点与点名状态
      system: "0", //对应系统1为出租车
      carPosition: [], // 动态车辆位置数据
      carPositionReq: [], // 动态车辆请求参数
      activeName: "报警数据",
      showTabName: "real", //tabtable具体显示tab

      area: "",
      street: "",
      address: "", //替换上面两个拼接地址
      color: "#66B1FF",
      plate: "", // 当前选中车辆
      paramData: {},
      page: 1,
      pageSize: 10,
      carDetailObj: {},
      realTimeTrail: [],
      total: 0,
      locatePoint: {},
      /** 显示监控页面右边功能 0:地图 */
      trailStep: 0,
      locations: [],
      dialogTableVisible: false, // 显示车辆详情
      carDteailTableVisible: false, // 显示车辆更多详细信息
      checkedNodes: [],
      bLoading: false,
      reqstatus: 0,
      reqData: [],
      showTab: true,
      // 数据看板联动样式修改
      TabHeight: "4vh",
      CarTreeHeight: "84vh",
      mapHeight: "84vh",
      treeBoxHeight: "72vh",

      showRealVideoCard: false, // 是否显示实时视频
      realVideoStyle: "z-index:-10;",
      showhistoryVideoCard: false, // 是否显示历史视频
      historyVideoStyle: "z-index: -10;",
      dialogDownloadVisible: false, // 下载时间选择显示
      dowloadParams: null,

      showTable: true,
      tableEnlarge: false,
      carDialogStyle: 'left:75%',
      menuActive: '0', //设置当前menu选中菜单
      carDetailTimer: null,
      setRefreshDailogStatus: false,


      dialogPlayVisible: false,//历史视频播放界面
      historyTitle: '历史视频',
      buttonValue: 0,
      showVideoControl: false,
      timeRound: '',
      device: null,
      channel: null,
      beginTime: 0,
      vehicleId: '',
      loading: false,
      openTimeOut: null,
      downLoadItemInfo: null,
      dialogDetailVisible:false,
      detailInfo:null,
      infoPlate:'',
    }
  },
  filters:{
    formataSize(v){
      return (v/1024/1024).toFixed(2)
    }
  },
  computed: {
    ...mapGetters({
      isRefreshLive: "getRefresh",
      nowMenuList: "nowMenuList"
    }),
    trailTableHgight: function() {
      return {
        halfHeighTable: this.bigTrailTable === "half",
        fullHeighTable: this.bigTrailTable === "full",
        noneHeighTable: this.bigTrailTable === "none"
      };
    },
    buttonPosition: function() {
      return `${parseInt(this.TabHeight) + 3}vh`;
    }
  },
  methods: {
    /** 时间间隔转换 */
    formateTimeRound (timeRound, flag) {
      let time = ''
      let timeDiff = ''
      let date = ''
      time = timeRound ? timeRound.split(' 至 ') : ''
      timeDiff = new Date(time[1]).getTime() - new Date(time[0]).getTime()
      if (flag) return parseInt(timeDiff / 1000)
      date = YHTools.FormatTimeBySeconds(timeDiff / 1000)
        ? YHTools.FormatTimeBySeconds(timeDiff / 1000)
        : '00:00'
      return date
    },
    /* 搜索时关闭车辆详细信息 */
    closeVehDialog() {
      this.dialogTableVisible = false;
      clearInterval(this.carDetailTimer);
      this.setRefreshDailogStatus = false;
      // this.$store.dispatch('setRefreshDailogStatus', false)
    },
    /** 下发指令 */
    handleContextMenu() {
      this.pushTableToAct();
    },
    /* 单击节点展示车辆基础信息   */
    handleNodeClick(node, checkedNodes) {
      if (this.trailStep === 0 && this.$refs.myMap) {
        this.$refs.myMap.reloadStstus();
        this.$refs.myMap.clearBusMarker();
      }
      this.showCarDetail(node.id);
      this.isClickCar = true;
      this.clickCarCph = node.id;
      //与轨迹回放联动
      this.$refs.myMap.setChoseCar(node.name);

      this.handleCheck(node.id, checkedNodes);
    },
    /* 关闭车辆基础信息面板 */
    handleDialog() {
      this.dialogTableVisible = false;
      clearInterval(this.carDetailTimer);
      this.setRefreshDailogStatus = false;
      // this.$store.dispatch('setRefreshDailogStatus', false)
    },
    /** 关闭 */
    loadingHide() {
      this.bLoading = false;
    },
    /** 车辆点名 显示在地图上 只有在trailStep等于0时  */
    carCalling(vehId, checkedNodes) {
      if (this.trailStep === 0) {
        this.isClickCar = true;
        this.clickCarCph = vehId;

        this.handleCheck(vehId, checkedNodes);
      }
    },
    /** 下发指令完成后，显示到指令信息中 */
    pushTableToAct() {
      if (this.trailStep === 0) {
        this.handleEnlarge();
        this.showTabName = "act";
      }
    },
    /* 展示车辆基础信息   */
    showCarDetail(vehId) {
      let deviceType = this.system === "2" ? "0" : this.system;
      queryVehicleCardById({
        vehIdStr: vehId,
        system: this.system,
        deviceType: deviceType
      }).then(res => {
        if (res.code === 1000) {
          if (res.data) {
            this.plate = res.data.cph;
            this.carDetailObj = res.data;
            this.area = "";
            this.street = "";
            if (res.data.lonAndLat) {
              this.getPath(res.data);
              this.$refs.myMap.setFitView(res.data.lonAndLat);
            } else {
              this.$message.warning("暂无车辆实时数据");
            }
            this.dialogTableVisible = true;
            clearInterval(this.carDetailTimer); //清空计时器
            this.setRefreshDailogStatus = true;
            // this.$store.dispatch('setRefreshDailogStatus', true)

            /** 如果正在播放视频，就让视频界面不显示 */
            if (this.showRealVideoCard) {
              this.closeRealVideo();
            }
            /** 如果正在播放视频，就让视频界面不显示 */
            if (this.showhistoryVideoCard) {
              this.closehistoryVideo();
            }
            /** 如果在轨迹回放界面 */
            if (this.trailStep >= 1) {
              this.carDialogStyle = "left:30vw";
            }
          }
        }
      });
    },
    /*  获取位置 把gps转为对应的经纬度   */
    getPath (data) {
      // oneGpsToLngLat(data.lonAndLat[0], data.lonAndLat[1]).then((res) => {
      //   this.getLocation(res[0], res[1])
      // })
      //不用转换经纬度
      this.getLocation(data.lonAndLat[0], data.lonAndLat[1])
    },
    /* 将经纬度转为地址信息 */
    getLocation(lng, lat) {
      let self = this;
      getPlace(lng, lat, false).then(res => {
        if (res) {
          self.address = res;
          // self.area = res.province + res.city + res.district
          // self.street = res.street + res.township + res.streetNumber
        }
      });
    },
    /* 选择复选框   展示车辆 */
    handleCheck(a, checkedNodes) {
      let self = this;
      self.$refs.myMap.reloadStstus(); // 将map请求status置空
      self.$refs.myMap.reloadtimerBus(); // 将map请求定时器清空
      let carPositionReq = []; // 默认置空
      let reqData = [];
      self.checkedNodes = [...checkedNodes];

      self.checkedNodes.forEach(item => {
        let index = checkedNodes.findIndex(
          element => item.pid === element.id || item.pId === element.id
        );
        if (index < 0 && item.type !== 4 && item.type !== 5) {
          carPositionReq.push(item.id);
        }
        if (item.type === 3) {
          // 轨迹回放
          reqData.push({
            id: item.id,
            name: item.name
          });
        }
      });

      self.reqData = reqData;
      self.carPositionReq = carPositionReq;
      /** 只有还没有查询出来轨迹时才能执行 */
      if (self.trailStep > 1) return;
      /** 取消勾选 */
      if (carPositionReq.length === 0) {
        self.$refs.myMap.clearBusMarker();
        if (self.trailStep === 0) {
          if (self.$refs.tabTable) self.$refs.tabTable.clear();
          if (self.$refs.tabTable) self.$refs.tabTable.narrow();
          self.narrow();
        }
        return;
      }
      self.addSpotCheck();
      /**地图模式 */
      self.setActiveIndex(
        self.trailStep === 0 ? "0" : "3",
        false,
        self.trailStep === 0
      );
      self.$refs.myMap.showBus(carPositionReq);
    },
    //添加抽查信息
    addSpotCheck() {
      let deviceType = this.system === "2" ? "0" : this.system;
      addSpotCheck({
        type: 1,
        ids: this.carPositionReq.join(","),
        system: this.system,
        deviceType
      }).then();
    },
    /** 点击轨迹回放|退出轨迹回放 */
    showTrailReplay(v) {
      if (v) {
        /** 退出 */
        this.trailStep = 0;
        this.narrow();
        if (this.$refs.tabTable) this.$refs.tabTable.narrow();
        /** 显示被勾选车辆的实时信息 */
        if (this.carPositionReq.length >= 0) {
          this.handleEnlarge(true);
        }
      } else {
        /** 进入 */
        this.trailStep = 1;
        this.fullscreen();
      }
    },
    /** 播放轨迹|停止轨迹 */
    showTrailReplayNotice(v) {
      if (v) {
        /** 轨迹 */
        this.trailStep = 2;
      } else {
        /** 播放轨迹 */
        this.trailStep = 3;
        this.showTrailReplayForm();
      }
    },
    /* 展示轨迹表格 */
    showHistoryTable(obj, resObj, isFirst,fileName) {
      this.trailStep = 2;
      this.$nextTick(() => {
        this.$refs.plTable.showHistoryTable(obj, resObj, isFirst,fileName);
      });
    },
    /** 轨迹表格点击后在地图上显示 */
    locatePointShow(row) {
      this.locatePoint = row;
    },
    /** trailstep=0 还原最初状态；显示树+map+实时信息栏 */
    narrow() {
      this.showTab = true;
      this.tableEnlarge = false;
      this.TabHeight = "4vh";
      this.CarTreeHeight = "84vh";
      this.mapHeight = "84vh";
      this.treeBoxHeight = "72vh";
      this.showTable = true;
    },
    //历史视频打开时关闭底部tab页面
    closeTab(){
      this.showTab = false
      this.TabHeight = '0vh'
      
    },
    /** 全屏 |刚刚进入轨迹回放界面 */
    fullscreen() {
      this.showTab = false;
      this.TabHeight = "0vh";
      this.CarTreeHeight = "90vh";
      this.mapHeight = "90vh";
      this.treeBoxHeight = "76vh";
    },
    /** 放大 |单击节点显示实时信息，指令信息 */
    enlarge() {
      this.showTab = true;
      this.tableEnlarge = false;
      this.TabHeight = "34vh";
      this.CarTreeHeight = "60vh";
      this.mapHeight = "60vh";
      this.treeBoxHeight = "44vh";
      this.showTable = true;
    },
    //显示tabtable 初始化
    handleEnlarge(flag) {
      this.enlarge();
      if (this.$refs.tabTable) this.$refs.tabTable.enlarge();
      if (flag) {
        if (this.$refs.tabTable) {
          this.$refs.tabTable.reset();
          this.$refs.tabTable.showTabTable(this.carPositionReq);
        }
      }
    },
    /** 显示轨迹回放表格 */
    showHistoryTableReduction() {
      this.showTab = false;
      this.tableEnlarge = false;
      this.TabHeight = "0vh";
      this.CarTreeHeight = "90vh";
      this.treeBoxHeight = "76vh";
      this.mapHeight = "90vh";
      this.showTable = true;
    },
    /** 显示轨迹回放回放参数 */
    showTrailReplayForm() {
      this.showTab = false;
      this.tableEnlarge = false;
      this.TabHeight = "0vh";
      this.CarTreeHeight = "90vh";
      this.treeBoxHeight = "42vh";
      this.mapHeight = "90vh";
      this.showTable = true;
    },
    /////////////////////////////////////////////////////////////////////////////////////////

    /** tab切换 显示视频系统
     * @param {*} flag 是否显示 视频界面
     * @param {*} key 历史/实时
     */
    showVideoCard(flag, key) {
      if (flag) {
        switch (key) {
          case "1":
            if (this.showhistoryVideoCard === true)
              this.stopHistioryVideoCard(true);
            /** 如果正在播放视频，就让视频界面显示 */
            if (this.showRealVideoCard) {
              this.realVideoStyle = "z-index:2000;";
              return;
            }
            /** 初始化视频界面显示 */
            this.showRealVideoCard = true;
            this.realVideoStyle = "z-index:2000;";
            this.$nextTick(function() {
              this.$refs.video.showVideo();
            });
            break;
          case "4":
            if (this.showRealVideoCard === true) this.stopRealVideoCard(true);
            /** 如果正在播放视频，就让视频界面显示 */
            if (this.showhistoryVideoCard) {
              this.historyVideoStyle = "z-index:2000;";
              return;
            }
            this.showhistoryVideoCard = true;
            this.historyVideoStyle = "z-index:2000;";
            this.$nextTick(function() {
              this.$refs.historyVideo.showVideo();
            });
            break;

          default:
            break;
        }
        this.setActiveIndex(key, true, key == "0");

        return;
      }
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showRealVideoCard) {
        this.closeRealVideo();
      }
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showhistoryVideoCard) {
        this.closehistoryVideo();
      }

      this.setActiveIndex(key, true, key == "0");
    },
    /** 查询实时车辆视频 显示视频系统 */
    handleToPlayVideo(params, channel, flag) {
      let isInitHD =
        this.showRealVideoCard === false &&
        this.realVideoStyle === "z-index:-10;";
      /** 还停留在历史视频界面 */
      if (this.showhistoryVideoCard === true) this.stopHistioryVideoCard(true);

      this.showRealVideoCard = true;
      this.realVideoStyle = "z-index:2000;";
      this.setActiveIndex("1");
      this.$nextTick(function() {
        this.$refs.video.handleToPlayVideo(params, channel, flag, isInitHD);
      });
    },
    /** 实时视频点击停止全部 */
    stopAllRealVideo() {
      if (this.$refs.carTree) this.$refs.carTree.stopAllVideo();
    },
    /** 实时视频点击退出视频 */
    exitRealVideo() {
      this.showRealVideoCard = false;
      this.$refs.myMap.outerVisibleClose();
      this.$refs.carTree.stopAllVideo();
    },
    /** 点击最小化实时视频 */
    closeRealVideo() {
      this.realVideoStyle = "z-index:-10;";
    },

    /** 点击还原实时视频 */
    reductionRealVideo() {
      this.showRealVideoCard = true;
      this.$nextTick(function() {
        this.realVideoStyle = "z-index:2000;";
      });
    },
    /** 实时视频 超过播放时限 清除选中状态 */
    closeRealPlay(a, b) {
      this.$refs.carTree.closePlay(a, b);
    },
    /** 点击历史视频需要关闭正在播放的实时视频 */
    stopRealVideoCard(flag) {
      if (!flag) this.$refs.video.stopAllVideo(true);
      this.showRealVideoCard = false;
      this.realVideoStyle = "z-index:-10;";
    },
    /** ************************************************************************************************************************* */
    /** 查询历史车辆视频 显示历史视频系统  */
    handleHistoryVideo(d, n, flag) {
      /** 还停留在实时视频界面 */
      if (this.showRealVideoCard === true) this.stopRealVideoCard(true);
      this.showhistoryVideoCard = true;
      this.historyVideoStyle = "z-index:2000;";
      this.setActiveIndex("4");

      this.$nextTick(function() {
        this.$refs.historyVideo.handleClick(d, n, flag);
      });
    },

    /** 历史视频点击退出视频 */
    stopAllHistoryVideo() {
      this.showhistoryVideoCard = false;
      this.$refs.myMap.outerVisibleClose();
      this.historyVideoStyle = "z-index:-10;";
      this.$nextTick(function() {
        if (this.$refs.carTree) this.$refs.carTree.stopAllHistoryVideo();
      });
    },
    /** 历史视频点击停止全部 */
    closeAllHistoryVideo() {
      if (this.$refs.carTree) this.$refs.carTree.stopAllHistoryVideo();
    },
    /** 点击最小化历史视频 */
    closehistoryVideo() {
      this.historyVideoStyle = "z-index:-10;";
    },
    /** 点击还原历史视频 */
    reductionhistoryVideo() {
      this.showhistoryVideoCard = true;
      this.$nextTick(function() {
        this.historyVideoStyle = "z-index:2000;";
      });
    },
    /** 修改 下载历史视频参数 */
    downLoadHistoryVideo (params, item) {
      this.dialogDownloadVisible = true
      this.dowloadParams = params;
      this.downLoadItemInfo = item;
    },
    /** 历史视频参数修改完成 */
    toHistoryDowload (params) {
      this.$refs.historyVideo.downLoadCheck(params, this.downLoadItemInfo)
      this.dialogDownloadVisible = false
    },
    /**播放历史视频 */
    playHistoryVideo (params, plate, vehicleId,beginTime) {
      // console.log('10000000000000',params, plate, vehicleId,beginTime)
     if(beginTime)this.beginTime = beginTime
      // this.downLoadItemInfo = params;
      if(plate)this.infoPlate = plate;
      if(vehicleId)this.vehicleId = vehicleId;
      this.buttonValue = 0
      this.dialogPlayVisible = true;
      if(params.val)this.timeRound = params.val;
      this.loading = true;
      this.historyTitle = `正在播放 ${this.infoPlate} CH${params.channel} ${this.timeRound}的历史视频`
      this.$nextTick(() => {
        this.buttonValue = 1;
        this.$refs.flvPlayModule.changeFrameWH();
      })
      this.openTimeOut = setTimeout(() => {
        this.loading = false;
      }, 15000);
    },
    /** */
    initParameter (params) {
      clearTimeout(this.openTimeOut)
      this.loading = false;
      const { device, channel } = params
      this.device = device;
      this.channel = channel;
      this.$refs.flvPlayModule.initParameter()
      this.showVideoControl = true;
      this.$refs.flvPlayModule.playHistoryVideo(params)
    },
    /**查看详情 */
    showDetail(params,plate){
      this.detailInfo = params;
      this.infoPlate = plate;
      this.dialogDetailVisible = true;
    },
    dialogClose () {
      this.$refs.historyVideo.close()
      this.$refs.flvPlayModule.flv_destroyAll();
    },
    /** 拖动播放 */
    dragChangeVideoTime (val) {
      this.$yhsdp.GBHistoryStreamSpeedControl(
        this.device,
        this.channel,
        this.beginTime + val
      )
      // this.$refs.flvPlayModule.playHistoryVideo({
      //   device: this.device, // 终端号
      //   channel: this.channel, // 通道号 （1~8）数组
      //   plate: this.infoPlate
      // });
      //拖动后恢复播放
      this.$refs.flvPlayModule.playBackVideoRecover({
        device: this.device, // 终端号
        channel: this.channel, // 通道号 （1~8）数组
        plate: this.infoPlate
      })
    },
    /**
     * 播放-暂停
     */
     playAndPause(num){
      this.$yhsdp.GBHistoryStreamSpeedControl(
        this.device,
        this.channel,
        0,
        num,//0-播放 1-暂停
      )
      if(num == 0) {
      //   this.$refs.flvPlayModule.playHistoryVideo({
      //   device: this.device, // 终端号
      //   channel: this.channel, // 通道号 （1~8）数组
      //   plate: this.infoPlate
      // });
      }
      
    },
    close (flag) {
      this.$refs.historyVideo.close(flag)
      this.$refs.flvPlayModule.flv_destroyAll()
    },
    /** 点击实时视频，需要去关闭正在播放的历史视频  */
    stopHistioryVideoCard(flag) {
      if (!flag) this.$refs.historyVideo.close(true);
      this.showhistoryVideoCard = false;
      this.historyVideoStyle = "z-index:-10;";
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    /** 车辆面板-实时视频  */
    toHandlePlayVideo(vehId, name) {
      this.$refs.carTree.toHandlePlayVideo(vehId, name);
    },
    /** 车辆面板-历史视频  */
    toHandlePlayHisVideo(param) {
      /** 还停留在实时视频界面 */
      if (this.showRealVideoCard === true) this.stopRealVideoCard(true);
      this.showhistoryVideoCard = true;
      this.historyVideoStyle = "z-index:2000;";
      this.setActiveIndex("4");

      this.$nextTick(function() {
        this.$refs.historyVideo.toHandlePlayHisVideo(param);
      });
    },
    /** 历史视频 -切换选中channel */
    handleChannelClick(id) {
      this.$refs.carTree.toHandlePlayHisVideo(id);
    },
    /** 车辆面板-轨迹回放 */
    toHandleTrail(vehId, name) {
      this.trailStep = 1;
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showRealVideoCard) {
        this.closeRealVideo();
      }
      /** 如果正在播放视频，就让视频界面不显示 */
      if (this.showhistoryVideoCard) {
        this.closehistoryVideo();
      }
      // this.setActiveIndex('3')

      this.$refs.myMap.showTrailReplay();
      this.$refs.carTree.toHandleTrail(vehId, name);
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    //设置map-当前menu选中菜单
    /**
     * key -当前menu
     * flag1 -map显示对应menu
     * flag2 -tabtable缩放
     */
    setActiveIndex(key, flag1, flag2) {
      this.menuActive = key;
      if (!flag1) this.$refs.myMap.setActiveIndex(key);
      if (flag2) {
        this.trailStep = 0;
        this.handleEnlarge(true);
      }
      if (
        this.showhistoryVideoCard === true &&
        this.historyVideoStyle === "z-index:2000;"
      ) {
        this.carDialogStyle = "left:30vw";
      } else if (
        this.showRealVideoCard === true &&
        this.realVideoStyle === "z-index:2000;"
      ) {
        this.carDialogStyle = "left:30vw";
      } else if (key === "3" && this.trailStep >= 1) {
        this.carDialogStyle = "left:30vw";
      } else {
        this.carDialogStyle = "left:75%";
      }
    },
    //定时更新面板数据
    getCarDetailInterval() {
      const timer = setInterval(() => {
        if (!this.dialogTableVisible) return;
        if (JSON.stringify(this.carDetailObj) === "{}") return;
        let deviceType = this.system === "2" ? "0" : this.system;
        queryVehicleCardById({
          vehIdStr: `v${this.carDetailObj.vehId}`,
          system: this.system,
          deviceType: deviceType
        }).then(res => {
          if (res.code === 1000) {
            if (res.data) {
              this.plate = res.data.cph;
              this.carDetailObj = res.data;
              this.area = "";
              this.street = "";
              if (res.data.lonAndLat) {
                this.getPath(res.data);
              }
            }
          }
        });
      }, 10000);
      this.carDetailTimer = timer;
    },
    //取消选中点名的车辆
    cancelClickCar() {
      this.isClickCar = true;
      this.clickCarCph = "";
    }
  },
  watch: {
    "isRefreshLive.refreshDailog"(newvalue) {
      if (newvalue === true) {
        // this.getCarDetailInterval()
      }
    },
    setRefreshDailogStatus(val) {
      if (val === true) {
        this.getCarDetailInterval();
      }
    }
  },
  created() {
    this.system = sessionStorage.getItem("system");
  },
  mounted() {
    
  },
  /** 页面销毁 清除监听 */
  beforeDestroy() {
    clearInterval(this.carDetailTimer);
    this.setRefreshDailogStatus = false;
    // this.$store.dispatch('setRefreshDailogStatus', false)
  }
};
</script>

<style lang="scss" scoped>
.monitoring {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  .mymap-box,
  .expand-tree-box {
    padding: 0 0 15px 0;
  }
  .expand-tree-box {
    position: absolute;
    z-index: 10;
    width: 21vw;
    .carTree {
      padding: 8px;
    }
  }
  .el-table thead th.is-leaf {
    padding: 2px 0 !important;
  }
  .reductionRealVideo {
    position: absolute;
    left: 0;
    z-index: 20;
  }
}
.theme-project-gps {
  .monitoring {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.theme-project-bus {
  .monitoring {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.card-body {
  width: 100%;
  height: 45vh;
  margin: 5px 0;
}
.info{
  padding:10px 60px 10px 20px;
  .single{
    margin-bottom: 22px;
  }
  .label{
    font-size: 12px;
    color: #999999;
    margin-bottom: 10px;
  }
  .information{
    font-size: 14px;
    color:#333333;
  }
  .flx{
    display: flex;
  }
  .w25{
    width: 25%;
  }
}
</style>
