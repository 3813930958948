var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoring" },
    [
      _c(
        "el-row",
        [
          _c(
            "div",
            { staticClass: "expand-tree-box" },
            [
              _c("car-tree", {
                ref: "carTree",
                attrs: {
                  showCheckBox: true,
                  trailStep: _vm.trailStep,
                  expand: true,
                  bLoading: _vm.bLoading,
                  carTreeHeight: _vm.CarTreeHeight,
                  treeBoxHeight: _vm.treeBoxHeight,
                  system: _vm.system
                },
                on: {
                  handleNodeClick: _vm.handleNodeClick,
                  handleCheck: _vm.handleCheck,
                  handleToPlayVideo: _vm.handleToPlayVideo,
                  handleHistoryVideo: _vm.handleHistoryVideo,
                  handleContextMenu: _vm.handleContextMenu,
                  closeVehDialog: _vm.closeVehDialog,
                  stopRealVideoCard: _vm.stopRealVideoCard,
                  stopHistioryVideoCard: _vm.stopHistioryVideoCard,
                  pushTableToAct: _vm.pushTableToAct,
                  carCalling: _vm.carCalling
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "mymap-box",
              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
            },
            [
              _c("veh-map", {
                ref: "myMap",
                staticStyle: { overflow: "hidden" },
                attrs: {
                  isClickCar: _vm.isClickCar,
                  clickCarCph: _vm.clickCarCph,
                  reqData: _vm.reqData,
                  system: _vm.system,
                  configObj: _vm.locations,
                  locatePoint: _vm.locatePoint,
                  carPositionReq: _vm.carPositionReq,
                  showRealVideoCard: _vm.showRealVideoCard,
                  showhistoryVideoCard: _vm.showhistoryVideoCard
                },
                on: {
                  loadingHide: _vm.loadingHide,
                  closeTab: _vm.closeTab,
                  showTrailReplay: _vm.showTrailReplay,
                  showTrailReplayNotice: _vm.showTrailReplayNotice,
                  showHistoryTable: _vm.showHistoryTable,
                  showCarDetail: _vm.showCarDetail,
                  showVideoCard: _vm.showVideoCard,
                  cancelClickCar: _vm.cancelClickCar
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("tabTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.trailStep == 0,
            expression: "trailStep == 0"
          }
        ],
        ref: "tabTable",
        staticStyle: { "z-index": "800", background: "white" },
        attrs: {
          carPositionReq: _vm.carPositionReq,
          tabHeight: _vm.TabHeight,
          showTab: _vm.showTab,
          showTabName: _vm.showTabName
        },
        on: { narrow: _vm.narrow, enlarge: _vm.enlarge }
      }),
      _vm.trailStep >= 2
        ? _c("plentyTable", {
            ref: "plTable",
            attrs: { system: _vm.system, paramData: _vm.paramData },
            on: { locatePointShow: _vm.locatePointShow }
          })
        : _vm._e(),
      _vm.dialogTableVisible
        ? _c("car-dialog", {
            style: _vm.carDialogStyle,
            attrs: {
              titlex: _vm.plate,
              address: _vm.address,
              checkObj: _vm.carDetailObj
            },
            on: {
              toHandlePlayHisVideo: _vm.toHandlePlayHisVideo,
              toHandlePlayVideo: _vm.toHandlePlayVideo,
              toHandleTrail: _vm.toHandleTrail,
              dialogVisible: _vm.handleDialog
            }
          })
        : _vm._e(),
      _vm.showRealVideoCard
        ? _c("realVideo", {
            ref: "video",
            style: _vm.realVideoStyle,
            attrs: { id: "realVideoBox" },
            on: {
              stopAllVideo: _vm.stopAllRealVideo,
              exit: _vm.exitRealVideo,
              closePlay: _vm.closeRealPlay,
              closeDialog: _vm.closeRealVideo
            }
          })
        : _vm._e(),
      _vm.showhistoryVideoCard
        ? _c("historyVideo", {
            ref: "historyVideo",
            style: _vm.historyVideoStyle,
            attrs: { id: "historyVideoBox", isJessibucaPro: true },
            on: {
              handleChannelClick: _vm.handleChannelClick,
              close: _vm.closeAllHistoryVideo,
              downLoadHistoryVideo: _vm.downLoadHistoryVideo,
              playHistoryVideo: _vm.playHistoryVideo,
              initParameter: _vm.initParameter,
              showDetail: _vm.showDetail
            }
          })
        : _vm._e(),
      _vm.dialogDownloadVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "下载历史视频",
                width: "500px",
                visible: _vm.dialogDownloadVisible,
                top: "18vh"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogDownloadVisible = $event
                }
              }
            },
            [
              _c("downloadLimit", {
                attrs: { params: _vm.dowloadParams },
                on: { submit: _vm.toHistoryDowload }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.dialogPlayVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.historyTitle,
                width: "48vw",
                visible: _vm.dialogPlayVisible,
                top: "14vh",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogPlayVisible = $event
                },
                close: _vm.dialogClose
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "card-body"
                },
                [
                  _c("videoList", {
                    ref: "flvPlayModule",
                    attrs: {
                      buttonValue: _vm.buttonValue,
                      isHistory: true,
                      showVideoControl: _vm.showVideoControl,
                      timeRound: _vm.timeRound
                    },
                    on: { dragChangeVideoTime: _vm.dragChangeVideoTime }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.dialogDetailVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "详情",
                width: "48vw",
                visible: _vm.dialogDetailVisible,
                top: "16vh"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogDetailVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "single" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("车牌号码")]),
                  _c("div", { staticClass: "information" }, [
                    _vm._v(_vm._s(_vm.infoPlate))
                  ])
                ]),
                _c("div", { staticClass: "flx" }, [
                  _c("div", { staticClass: "single" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("开始时间")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(_vm._s(_vm.detailInfo.beginTime))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "single",
                      staticStyle: { "margin-left": "30px" }
                    },
                    [
                      _c("div", { staticClass: "label" }, [_vm._v("结束时间")]),
                      _c("div", { staticClass: "information" }, [
                        _vm._v(_vm._s(_vm.detailInfo.endTime))
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "single" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("报警状态")]),
                  _c("div", { staticClass: "information" }, [
                    _vm._v(_vm._s(_vm.detailInfo.alarmFlag))
                  ])
                ]),
                _c("div", { staticClass: "flx" }, [
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("时长")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(_vm._s(_vm.formateTimeRound(_vm.detailInfo.val)))
                    ])
                  ]),
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("大小（MB）")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(
                        _vm._s(_vm._f("formataSize")(_vm.detailInfo.fileSize))
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "flx" }, [
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("码流类型")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(
                        _vm._s(
                          _vm.detailInfo.streamType == 1
                            ? "主码流"
                            : _vm.detailInfo.streamType == 2
                            ? "子码流"
                            : "所有码流"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("通道号")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v("CH" + _vm._s(_vm.detailInfo.channel))
                    ])
                  ]),
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("音视频资源类型")
                    ]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(
                        _vm._s(
                          _vm.detailInfo.resType == 1
                            ? "音频"
                            : _vm.detailInfo.resType == 2
                            ? "视频"
                            : _vm.detailInfo.resType == 3
                            ? "视频或音频"
                            : "音视频"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "single w25" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("存储类型")]),
                    _c("div", { staticClass: "information" }, [
                      _vm._v(
                        _vm._s(
                          _vm.detailInfo.storageType == 1
                            ? "主存储器"
                            : _vm.detailInfo.storageType == 2
                            ? "灾备存储器"
                            : "所有存储器"
                        )
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }